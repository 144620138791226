import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { NavThemeProvider, AppOverlayProvider, AppProvider } from '@Context/AppContext';
import SEO from '@Components/SEO';
import Layout from '@Components/Layout';
import ContentfulModule from '@Src/components/ContentfulModule';
import { IBlogPostPage, IPageModule } from '@Types/ContentfulTypes';
import { Heading01, SmallHeading02, TextLayout } from '@Components/Text';
import BlockLayout from '@Src/components/BlockLayout';
import '@GraphQL/fragments';
import * as styles from './index.module.scss';

export interface Props {
	location: {
		pathname: string;
	};
	data: {
		contentfulBlogPost: IBlogPostPage;
	};
}

const BlogPost = ({ data, location }: Props): ReactElement => {
	const { pageModules, heroModule, header, author, seoSettings } = data.contentfulBlogPost;
	const menuTheme = heroModule?.insetTop ? 'creme': heroModule?.theme;

	return (
		<AppProvider type="blogpost" path={location.pathname}>
			<AppOverlayProvider>
				<NavThemeProvider theme={menuTheme}>
				<Layout>
					<SEO
						location={location.pathname}
						title={seoSettings?.title || header}
						description={seoSettings?.description}
						// meta={seoSettings?.metaTags}
						image={seoSettings?.image}
					/>
					<BlockLayout>
						{heroModule && <ContentfulModule key={heroModule.id} type={heroModule.type} data={heroModule} />}

						<section  className={styles.root}>
							<TextLayout className={styles.content}>
								<> 
									{!heroModule && (
										<Heading01 >
											{header}
										</Heading01>
									)}
										
									{author && (
										<div className={styles.author}>
											<SmallHeading02 text='Author:' />
											<SmallHeading02 text={author} />
										</div>
									)}
								</>
							</TextLayout>
								
						</section>
						{pageModules &&
							pageModules.map(
								(module: IPageModule): ReactElement => (
									<ContentfulModule key={module.id} type={module.type} data={module} />
								)
							)}
					</BlockLayout>
				</Layout>
				</NavThemeProvider>
			</AppOverlayProvider>
		</AppProvider>
	);
};

export default BlogPost;

export const query = graphql`
	query($id: String!) {
		contentfulBlogPost(id: { eq: $id }) {
            header
			author
			... on WPPageNode {
				...PageFields
				... on WPHeroPageNode {
					heroModule {
						... on WPPageModuleNode {
							...Hero
						}
					}
					pageModules {
						... on WPPageModuleNode {
							...InfographicsModule
							...TextBackgroundImageModule
							...TextImageModule
							...TextModule
							...MediaModule
							...VideoModule
							...RichTextModule
							...PersonalCards
							...ProductCards
							...ArticleCards
							...PartnerProducts
							...CtaModule
							...QuoteSlider
							...ColumnModule
						}
					}
				}
			}
		}
	}
`;
